// const url = 'https://jsonplaceholder.typicode.com/users/1'
// const url = 'http://192.168.0.148:8000/account/login/'
// imexp init from "@/api/fetch_ini";
// imexp axi from "@/api";
// imexp axi from "@/plugins/axios";


function authMod( axi ) {
    return {
        // logout:"/account/api/v1/logout/",
        logout:"/allauth_local/account/logout/", // вконце обязательный слешь
        login: "/allauth_local/account/login/", // вконце обязательный слешь
        async login_del(email, password) {
            try {
                // const response = await axi.post('/account/api/v1/login/', {email, password})
                alert('!!!!!!!')
                window.console.log('20')
                const response = await axi.post('/allauth_local/account/login/', {email, password})
                window.console.log('30', response)
                let resp = {error: false}
                resp.data = await response.data
                localStorage.setItem('access_token', resp.data.key)
                return resp
            } catch (error) {
                return {error: true, data: error.response.data || {}}
            }
        },
        async logout_off() {
            try {
                const response = await axi.post('/account/api/v1/logout/')
                let resp = {error: false}
                resp.data = await response.data
                localStorage.removeItem('access_token')
                return resp
            } catch (error) {
                return {error: true, data: error.response.data || {}}
            }
        },
        async registration(email, password) {
            try {
                const response = await axi.post('/account/registration/', {email, password1: password, password2: password})
                let resp = {error: false}
                resp.data = await response.data
                return resp
            } catch (error) {
                return {error: true, data: error.response.data || {}}
            }
        },
        async reset_password(email) {
            try {
                const response = await axi.post('/account/api/v1/password/reset/', {email})
                let resp = {error: false}
                resp.data = await response.data
                return resp
            } catch (error) {
                return {error: true, data: error.response.data || {}}
            }
        },
        async reset_password_confirm(new_password1, token, uid) {
            try {
                let response = axi.post('account/api/v1/password/reset/confirm/', {
                    new_password1,
                    new_password2: new_password1,
                    token,
                    uid
                })
                let resp = {error: false}
                resp.data = await response.data
                return resp
            } catch (error) {
                return {error: true, data: error.response.data || {}}
            }
        },
    }
}

export default authMod( window.axios )
